import { template as template_a30a42653816487f91f36e7dc4aacca4 } from "@ember/template-compiler";
const FKLabel = template_a30a42653816487f91f36e7dc4aacca4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
