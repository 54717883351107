import { template as template_5804556779b74849933b9985123be01a } from "@ember/template-compiler";
const FKText = template_5804556779b74849933b9985123be01a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
