import { template as template_3308c52845344eb187b1e65b296a6118 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3308c52845344eb187b1e65b296a6118(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
